import logo from "./logo.webp"

const  clientData = {
   client_entity: 34,
   attorney_firm: 'farmerwright.com',
    attorney_name: 'Todd Farmer',
    attorney_number: '270-443-4431',
    attorney_email: 'todd@farmerwright.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_-_farmer_wright+(720p).mp4',
    mainColor: '#D3BC90',
    secondaryColor: '#0056A5',
    siteLink: 'http://www.farmerwright.com',
    logo
}

export default clientData